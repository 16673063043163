<template>
  <div class="col-md-12 mb-3">
    <form @submit.prevent="processcreate">
      <h2>Get images from the image API.</h2>
      <p>Fill in the type of image and the amount, and we'll get them to you!</p>
      <b>Note that the images in almost all catagories are a mix-match of SFW and NSFW.</b>
      <p>*: "Both" will do a 50/50 split between the requested category. Meaning that it will only work with amount 2 or 4. Using anything else will result in an error.</p>
      <p>If nothing appears when clicking, that means that this category has no videos for example. Only 'latex', 'heels', 'fashion' and 'cosplay' have videos.</p>
      <label>
        Type: <br>
        <select
        v-model="form.type">
        <option value="both">Both*</option>
        <option>image</option>
        <option>video</option>
        </select>
      </label> <br>
      <label>
        Category: <br>
        <select
          v-model="form.query">
          <optgroup label="Generic">
            <option>latex</option>
            <option>heels</option>
            <option>fashion</option>
            <option>cosplay</option>
            <option>pantyhose</option>
            <option value="wonderwoman">wonder woman</option>
          </optgroup>
          <optgroup label="ASFR">
            <option>mannequin</option>
            <option>fembot</option>
            <option>sexdoll</option>
            <option>statue</option>
            <option>officedrone</option>
          </optgroup>
        </select>
      </label> <br>
      <label>
        Amount (max 5): <br>
        <input
          v-model="form.amount"
          type="number"
          min="1"
          max="5"
        >
      </label> <br>
      <button type="submit">
        Submit
      </button>
    </form>
    <br />

    <div v-for="(image, index) in image_list" :key="index">
      <img :src="image.image" />
    </div>
    <br>
    <div v-for="(image, index) in video_list" :key="index">
      <video controls muted autoplay>
        <source :src="image.image">
      </video>
    </div>
    <p id="error"></p>
  </div>
</template>

<script>
export default {
  data:
    function () {
        return {
            form: {
                query: '',
                amount: '',
                type: ''
            },
            image_list: [],
            video_list: [],
        }
    },
    methods: {
        processcreate: async function () {
          this.image_list = ''
          this.video_list = ''
          document.getElementById('error').innerHTML= ''
          const { type, amount } = this.form
          if (amount == 0 || amount > 5) {
            document.getElementById('error').innerHTML = 'This error should not appear. Were you messing around in DevTools?'
            return
          }
          if (type === "both" && (amount == 2 || amount == 4)) {
            await this.actualrequest(this.form)
          } else if ( (type === "both" ) && (amount !== 2 && amount !== 4) ) {
            document.getElementById('error').innerHTML = 'Both is selected, but amount is not equal to 2 or 4!'
          } else {
            await this.actualrequest(this.form)
          }
        },
        actualrequest: async function(data) {
          const response = await this.axios.post('https://imageapi-workers-api.masterwayz.nl/', data)
          if ( response.data.some(error => error.message === 'Internal Server Error') ) {
            document.getElementById('error').innerHTML = 'Either something went wrong on the backend, or most likely the selected type and category variant does not exist.'
            return
          }
          this.image_list = response.data.filter(thing => thing.type == "image")
          this.video_list = response.data.filter(thing => thing.type == "video")
        },
    }
}
</script>

<style>
img {
  float: left;
  max-width: 70vw;
  height: auto;
  margin: 10px;
}

video {
  float: left;
  max-width: 70vw;
  height: auto;
  margin: 10px;
}
</style>